<template>
  <div class="error-404">
    <div class="error-404__content">
      <div class="heart">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#d1120f" viewBox="0 0 448 512"><path d="M368 128c0 44.4-25.4 83.5-64 106.4V256c0 17.7-14.3 32-32 32H176c-17.7 0-32-14.3-32-32V234.4c-38.6-23-64-62.1-64-106.4C80 57.3 144.5 0 224 0s144 57.3 144 128zM168 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM3.4 273.7c7.9-15.8 27.1-22.2 42.9-14.3L224 348.2l177.7-88.8c15.8-7.9 35-1.5 42.9 14.3s1.5 35-14.3 42.9L295.6 384l134.8 67.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L224 419.8 46.3 508.6c-15.8 7.9-35 1.5-42.9-14.3s-1.5-35 14.3-42.9L152.4 384 17.7 316.6C1.9 308.7-4.5 289.5 3.4 273.7z"/></svg>
        <h1><b>Error 404</b></h1>
        <h3>Page not found</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
.error-404 {
  min-height: 58vh;
  &__content {
    margin: 0 auto;
    margin-top: 25% !important;
    @keyframes latidos {
      from {
        transform: none;
      }
      50% {
        transform: scale(1.4);
      }
      to {
        transform: none;
      }
    }

    .heart {
      display: inline-block;
      font-size: 15px;
      color: inherit;
      animation: latidos 0.9s infinite;
      transform-origin: center;
    }
  }
}
svg {
  width: 80px;
}
</style>
